import React, { useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';

type AuthorModalProps = {
    open: boolean;
    onClose: () => void;
    onSave: (author: { name: string; profilePicture: File | null }) => void;
};

const NovoAutorModal: React.FC<AuthorModalProps> = ({ open, onClose, onSave }) => {
    const [name, setName] = useState('');
    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    const [profilePictureFileName, setProfilePictureFileName] = useState('');

    const handleSave = () => {
        onSave({ name, profilePicture });
        setName(''); // Reset form
        setProfilePicture(null); // Reset form
        setProfilePictureFileName(''); // Reset file name
        onClose(); // Close modal
    };

    const handleProfilePictureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setProfilePicture(file);
            setProfilePictureFileName(file.name);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Add New Author</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    To add a new author, please enter the name and upload a profile picture.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <Button
                    variant="contained"
                    component="label"
                >
                    Upload Profile Picture
                    <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={handleProfilePictureChange}
                    />
                </Button>
                <TextField
                    margin="dense"
                    id="profilePictureFileName"
                    label="Selected Profile Picture"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={profilePictureFileName}
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NovoAutorModal;
