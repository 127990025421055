import React, { useState } from 'react';
import { Button, TextField, Box, Card, CardContent, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Signup: React.FC = () => {
    const [nomeAdquirente, setNomeAdquirente] = useState<string>('');
    const [emailAdquirente, setEmailAdquirente] = useState<string>('');
    const [senhaAdquirente, setSenhaAdquirente] = useState<string>('');
    const [nomeLocatario, setNomeLocatario] = useState<string>('');
    const [numeroCelular, setNumeroCelular]= useState<string>('');
    const navigate = useNavigate();

    const handleSubmit = async () => {
        const data = {
            nomeAdquirente: nomeAdquirente,
            emailAdquirente: emailAdquirente,
            senhaAdquirente: senhaAdquirente,
            nomeLocatario: nomeLocatario,
            numeroCelular: numeroCelular
        };

        try {
            const response = await axios.post(`${process.env.REACT_APP_TERRABOOKS_API_URL}/api/tenants`, data);
            console.log(response.data);

            if (response.status === 201) {
                navigate('/login');
            }

        } catch (error) {
            console.log(error);
        }
    }
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Faz com que o Box ocupe toda a altura da tela
            }}
        >
            <Card sx={{ minWidth: 275 }}>
                <CardContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        '& .MuiTextField-root': { m: 1, width: '25ch' }, // Aplica margem e define largura para os campos de texto
                        '& .MuiButton-root': { m: 1 }, // Aplica margem ao botão
                    }}
                >
                    <Typography variant="h5" component="div" sx={{ m: 2 }}>
                        Sign Up
                    </Typography>
                    <TextField label="Nome" variant="outlined" value={nomeAdquirente} onChange={e => setNomeAdquirente(e.target.value)} />
                    <TextField label="Email" variant="outlined" value={emailAdquirente} onChange={e => setEmailAdquirente(e.target.value)} />
                    <TextField label="Password" type="password" variant="outlined" value={senhaAdquirente} onChange={e => setSenhaAdquirente(e.target.value)} />
                    <TextField label="Nome do locatário" variant="outlined" value={nomeLocatario} onChange={e => setNomeLocatario(e.target.value)} />
                    <TextField label="Celular" variant="outlined" value={numeroCelular} onChange={e => setNumeroCelular(e.target.value)} />
                    <Button variant="contained" onClick={handleSubmit}>Sign Up</Button>
                </CardContent>
            </Card>
        </Box>
    );
};

export default Signup;
