// src/pages/Blog.tsx
import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import Menu from '../components/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { createBlogPost, deleteBlogPost, fetchBlogPosts, publishBlogPost } from '../redux/slices/blogPostsSlice';
import NovoBlogPostModal from '../components/NovoBlogPostModal';
import { BlogPost } from '../models/BlogPost';
import PublishIcon from '@mui/icons-material/Publish';
import DeleteIcon from '@mui/icons-material/Delete';
// import NovoBlogPostModal from '../components/NovoBlogPostModal'; // Assuma que este é um componente similar a NovoAutorModal

const Blog: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const [modalOpen, setModalOpen] = useState(false);
    const blogPosts = [...useSelector((state: RootState) => state.blogPosts)].sort((a, b) => b.dateTimeCreated.localeCompare(a.dateTimeCreated));

    useEffect(() => {
        dispatch(fetchBlogPosts());
    }, [dispatch]);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleDelete = (id: string) => {
        // Despacha a ação de exclusão
        dispatch(deleteBlogPost(id));
        // Opcional: Mostrar uma notificação ou diálogo de confirmação antes de excluir
    };


    const handleSavePost = (blogPost: BlogPost) => {
        dispatch(createBlogPost(blogPost));
    };

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'title', headerName: 'Title', width: 200 },
        {
            field: 'dateTimeCreated',
            headerName: 'Data de criação',
            width: 110,
            valueGetter: (params: GridValueGetterParams) =>
                new Date(params.row.dateTimeCreated).toLocaleDateString('pt-BR'),
        },
        {
            field: 'dateTimePublished',
            headerName: 'Data de publicação',
            width: 110,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.dateTimePublished ? 
                new Date(params.row.dateTimePublished).toLocaleDateString('pt-BR') :
                '',
        },
        { field: 'authorId', headerName: 'Author ID', width: 90 },
        {
            field: 'actions',
            type: 'actions',
            headerName: '',
            width: 250,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        params.row.isPublished ? (
                            <PublishIcon
                                sx={{
                                    // Estilo para quando já estiver publicado
                                    color: 'grey.500', // Cor cinza para indicar desativado
                                    borderRadius: 1,
                                    p: '5px',
                                }}
                            />
                        ) : (
                            <PublishIcon
                                sx={{
                                    // Estilo para quando ainda não estiver publicado
                                    color: 'primary.main', // Cor principal para indicar ativo
                                    borderRadius: 1,
                                    p: '5px',
                                    '&:hover': {
                                        backgroundColor: 'primary.light', // Fundo no hover para indicar interatividade
                                    },
                                }}
                            />
                        )
                    }
                    label="Publicar"
                    onClick={() => {
                        if (!params.row.isPublished) {
                            dispatch(publishBlogPost(params.id.toString()))
                        } else {
                            // Opcional: Mostre alguma mensagem ou faça alguma ação se o post já estiver publicado
                            console.log('Este post já está publicado.');
                        }
                    }}
                />,
                <GridActionsCellItem
                    icon={
                        <DeleteIcon 
                            sx={{
                                // Estilo para parecer um botão
                                color: 'error.main', // Cor vermelha para ação de excluir
                                borderRadius: 1,
                                p: '5px',
                                '&:hover': {
                                    backgroundColor: 'error.light', // Fundo mais claro no hover
                                    cursor: 'pointer', // Mudar o cursor para indicar clicabilidade
                                },
                            }}
                        />
                    }
                    label="Excluir"
                    onClick={() => handleDelete(params.id.toString())}
                />,
            ],
        },
    ];

    return (
        <>
            <Menu />
            <Box sx={{ height: 400, width: '100%', mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleOpenModal}>Novo Post</Button>
                </Box>
                <NovoBlogPostModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    onSave={(blogPost: BlogPost) => handleSavePost(blogPost)}
                />
                {/* <NovoBlogPostModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveBlogPost}
                /> */}
                <Typography variant="h4" gutterBottom component="div" sx={{ m: 2 }}>
                    Blog Posts
                </Typography>
                <DataGrid
                    rows={blogPosts}
                    columns={columns}
                    // pageSize={5}
                    checkboxSelection
                />
            </Box>
        </>
    );
};

export default Blog;
