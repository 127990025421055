import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Menu: React.FC = () => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDrawer = (isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setOpen(isOpen);
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setOpen(true)}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Página Inicial
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                <List>
                    <ListItem button onClick={() => { navigate('/'); toggleDrawer(false); }}>
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate('/autores'); toggleDrawer(false); }}>
                        <ListItemText primary="Autores" />
                    </ListItem>
                    <ListItem button onClick={() => { navigate('/blog'); toggleDrawer(false); }}>
                        <ListItemText primary="Blog" />
                    </ListItem>
                </List>
            </Drawer>
        </>
    );
};

export default Menu;
