// src/features/authors/authorsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Author } from '../../models/Author';
import { RootState } from '../store';

const REFRESH_INTERVAL = 15; // minutos

// Thunk assíncrono para buscar os autores
export const fetchAuthors = createAsyncThunk<AuthorsState, void, { state: RootState}>(
    'authors/fetchAuthors',
    async (_, { getState, rejectWithValue }) => {
        const { lastUpdated } = getState().authorsState;
        const now = new Date();
        const lastFetchDate = lastUpdated ? lastUpdated : null;
        const shouldRefresh = !lastFetchDate || (now.getTime() - new Date(lastFetchDate).getTime()) > REFRESH_INTERVAL * 60000;
        if (!shouldRefresh) {
            // Se a última busca foi recente, não faz uma nova
            const currentState: AuthorsState = getState().authorsState;
            return currentState; // Certifique-se de que isso corresponde ao AuthorState
        }
        try {
            const response = await fetch(process.env.REACT_APP_TERRABOOKS_API_URL + '/authors', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });
            return { 
                data: await response.json(), 
                lastUpdated: now.toISOString(),
                loaded: true,
                loading: false,
                error: null,
            };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const createAuthor = createAsyncThunk<Author, { name: string; profilePicture: File | null }>(
    'authors/createAuthor',
    async ({ name, profilePicture }) => {
        // Inicializa um novo objeto FormData
        const formData = new FormData();

        // Adiciona o nome do autor como um JSON stringified
        formData.append('json-payload', JSON.stringify({ name }));

        // Adiciona o arquivo de imagem, se disponível
        if (profilePicture) {
            formData.append('profilePicture', profilePicture);
        }

        const response = await fetch(`${process.env.REACT_APP_TERRABOOKS_API_URL}/authors`, {
            method: 'POST',
            body: formData, // Envia o FormData
            credentials: 'include', // Essencial para incluir cookies na solicitação
        });

        if (!response.ok) {
            throw new Error('Failed to create new author');
        }
        return (await response.json()) as Author;
    }
);

// const initialState: Author[] = [];

interface AuthorsState {
    data: Author[];
    loaded: boolean;
    loading: boolean;
    error: string | null;
    lastUpdated: string | null;
}

const initialState: AuthorsState = {
    data: [],
    loaded: false,
    loading: false,
    error: null,
    lastUpdated: null,
}

export const authorsSlice = createSlice({
    name: 'authors',
    initialState,
    reducers: {
        // Reducers síncronos para seu slice de autores, se necessário
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchAuthors.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchAuthors.fulfilled, (state, action) => {
            state.data = action.payload.data; // Supondo que o payload retornado seja um objeto com a propriedade 'data'
            state.loading = false;
            state.loaded = true;
            state.lastUpdated = action.payload.lastUpdated; // Supondo que o payload retornado tenha 'lastUpdated'
        })
        .addCase(fetchAuthors.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message || null;
        })
        .addCase(createAuthor.fulfilled, (state, action) => {
            state.data.push(action.payload);
        })
    }
});

// Exporte o reducer para incluí-lo no store
export default authorsSlice.reducer;
