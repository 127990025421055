import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BlogPost } from '../../models/BlogPost';
import moment from 'moment';

export const fetchBlogPosts = createAsyncThunk<BlogPost[]>(
    'blog/fetchBlogPosts',
    async () => {
        const response = await fetch(process.env.REACT_APP_TERRABOOKS_API_URL + '/blogposts', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include'
        });
        // console.log(await response.json());
        return await response.json() as BlogPost[];
    }
);

export const publishBlogPost = createAsyncThunk<void, string>(
    'blogPosts/publishBlogPost',
    async (id: string) => {
        const utcDate = moment().utc().format(); // Obtem a data e hora atual em UTC
        const payload = {
            dateTimePublished: utcDate,
        };

        const response = await fetch(`${process.env.REACT_APP_TERRABOOKS_API_URL}/blogposts/${id}/publish`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(payload), // Envia o payload com a data de publicação
        });

        if (!response.ok) {
            throw new Error('Failed to publish the blog post');
        }
    }
);

export const deleteBlogPost = createAsyncThunk<void, string>(
    'blogPosts/deleteBlogPost',
    async (id: string, { dispatch }) => {
        await fetch(`${process.env.REACT_APP_TERRABOOKS_API_URL}/blogposts/${id}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    }
);

export const createBlogPost = createAsyncThunk<BlogPost, BlogPost>(
    'blogPosts/createBlogPost',
    async (blogPost: BlogPost) => {
        const formData = new FormData();

        // Separa o OgImage do restante dos dados do blogPost
        const { ogImage, coverImage, ...otherData } = blogPost;

        // Adiciona o OgImage ao FormData, se existir
        if (ogImage instanceof Blob) {
            formData.append('ogImage', ogImage, ogImage.name);
        }
        if (coverImage instanceof Blob) {
            formData.append('coverImage', coverImage, coverImage.name);
        }
        
        // Converte os demais dados do blogPost em JSON e adiciona ao FormData
        // O nome 'json' é arbitrário e será usado no backend para identificar os dados do post
        formData.append('json', JSON.stringify(otherData));

        const response = await fetch(process.env.REACT_APP_TERRABOOKS_API_URL + '/blogposts', {
            method: 'POST',
            credentials: 'include',
            body: formData, // Envie formData atualizado
            // Remova a linha do 'Content-Type', deixe que o navegador defina isso automaticamente
        });

        return await response.json() as BlogPost; // Supondo que a API retorna o post do blog criado
    }
);

const initialState: BlogPost[] = [];

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchBlogPosts.fulfilled, (state, action) => {
            return action.payload;
        })
        .addCase(createBlogPost.fulfilled, (state, action) => {
            console.log(action.payload);
            state.push(action.payload);
        })
        .addCase(deleteBlogPost.fulfilled, (state, action) => {
            // A ação deleteBlogPost não retorna um payload, então usamos o argumento de ação para obter o ID
            // e atualizamos o estado removendo o post com aquele ID
            const index = state.findIndex(post => post.id === action.meta.arg);
            if (index !== -1) {
                state.splice(index, 1);
            }
        })
        .addCase(publishBlogPost.fulfilled, (state, action) => {
            const index = state.findIndex(post => post.id === action.meta.arg);
            if (index !== -1) {
                state[index].isPublished = true; // Atualiza o post para refletir que agora está publicado
            }
        });
    }
});

export default blogSlice.reducer;