import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, MenuItem, InputLabel, Select, FormControl } from '@mui/material';
import { BlogPost } from '../models/BlogPost';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store';
import { fetchAuthors } from '../redux/slices/authorsSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Checkbox, FormControlLabel } from '@mui/material';
import moment from 'moment';

type BlogPostModalProps = {
    open: boolean;
    onClose: () => void;
    onSave: (blogPost: BlogPost) => void;
};

const NovoBlogPostModal: React.FC<BlogPostModalProps> = ({ open, onClose, onSave }) => {
    const [slug, setSlug] = useState('');
    const [title, setTitle] = useState('');
    const [coverImageFileName, setCoverImageFileName] = useState('');
    const [authorId, setAuthorId] = useState('');

    const [excerpt, setExcerpt] = useState('');
    const [ogImage, setOgImage] = useState('');
    const [ogImageFile, setOgImageFile] = useState<File | null>(null);
    const [content, setContent] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const quillRef = useRef<ReactQuill>(null);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [coverImageFile, setCoverImageFile] = useState<File | null>(null);
    const [publish, setPublish] = useState(false);


    const handleOgImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            setOgImage(event.target.files[0].name); // Atualize o nome do arquivo para exibição
            setOgImageFile(event.target.files[0]); // Armazene o arquivo para upload
        }
    };

    const handleCoverImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            setCoverImageFileName(event.target.files[0].name); // Cria um URL temporário para visualização da imagem
            setCoverImageFile(file); // Armazene o arquivo para upload
        }
    };

    const handleImage = () => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.click();
    
        input.onchange = async () => {
            if (input.files && input.files.length > 0) {
                const file = input.files[0];
                const formData = new FormData();
                formData.append('image', file); 
                try {
                    // Usando fetch para enviar os dados
                    const response = await fetch(process.env.REACT_APP_TERRABOOKS_API_URL + '/blog/images', {
                        method: 'POST',
                        body: formData, // Enviando o formData diretamente
                        credentials: 'include', // Essencial para incluir cookies na solicitação
                    });
    
                    // O Axios já desempacota o JSON, então você pode usar diretamente response.data
                    const data = await response.json();
                    if (quillRef.current) {
                        const quill = quillRef.current.getEditor();
                        const range = quill.getSelection(true);
                        setUploadedImages(prevUploadedImages => [...prevUploadedImages, data.objectId]);
                        quill.insertEmbed(range.index, 'image',  `${process.env.REACT_APP_TERRABOOKS_API_URL}${data.uri}`);
                    }
                } catch (error) {
                    console.error('Error:', error);
                    // Se estiver usando Axios, você pode querer verificar error.response para mais detalhes
                }
            }
        };
    };

    const authorsState = useSelector((state: RootState) => state.authorsState);

    // Atualize os módulos do Quill para usar handleImage
    const modules = React.useMemo(() => ({
        toolbar: {
            container: [
                [{ 'header': '1'}, {'header': '2'}, {'header': [3, 4, 5, 6]}, {'font': [] }],
                [{ 'size': [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'script': 'sub'}, { 'script': 'super' }],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'indent': '-1'}, { 'indent': '+1' }],
                [{ 'align': [] }],
                ['link', 'image', 'video'],
                ['blockquote', 'code-block'],
                ['clean']
            ],
            handlers: {
                'image': handleImage
            }
        }
    }), []); // Adicione quaisquer dependências se necessário

    useEffect(() => {
        dispatch(fetchAuthors());
    }, [dispatch])

    const handleSave = () => {
        const updateImagePaths = (content: string) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(content, 'text/html');
            doc.querySelectorAll('img').forEach(img => {
                const src = img.getAttribute('src');
                if (src) {
                    const fileName = src.split('/').pop(); // Isso pega a última parte da URL, que você mencionou ser o objectId.
                    if (fileName) {
                        img.setAttribute('src', fileName);
                    } 
                }
            });
            return doc.body.innerHTML;
        }

        const blogPost = {
            slug: slug,
            title: title,
            authorId: authorId,
            dateTimeCreated: moment().utc().toISOString(),
            dateTimePublished: publish ? moment().utc().toISOString() : null,
            excerpt: excerpt,
            ogImage: ogImageFile,
            content: updateImagePaths(content),
            contentImages: uploadedImages,
            coverImage: coverImageFile,
            isPublished: publish
        };

        onSave(blogPost); // Nota: Estou gerando um novo UUID para o autor, ajuste conforme necessário.
        setSlug('');
        setTitle('');
        setCoverImageFileName('');
        setAuthorId('');
        setExcerpt('');
        setOgImage('');
        setContent('');
        setPublish(false);
        onClose(); // Fecha o modal
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="lg"
            fullWidth={true}
            PaperProps={{
                style: {
                    height: '90vh',
                    overflow: 'auto',
                },
            }}
        >
            <DialogTitle>Adicionar Novo Post do Blog</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Para adicionar um novo post ao blog, por favor preencha os seguintes campos.
                </DialogContentText>
                <FormControl fullWidth margin="dense">
                    <InputLabel id="author-select-label">Autor</InputLabel>
                    <Select
                        labelId="athor-select-label"
                        id="authorId"
                        value={authorId}
                        label="Autor"
                        onChange={(e: any) => setAuthorId(e.target.value)}
                    >
                        {authorsState.data.map((author) => (
                            <MenuItem key={author.id} value={author.id}>
                                {author.name}
                            </MenuItem>
                        ))}
                    </Select>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="slug"
                        label="Slug"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={slug}
                        onChange={(e) => setSlug(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="title"
                        label="Título"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        component="label" // Faz o botão agir como um label para o input de arquivo oculto
                    >
                        Carregar Imagem de Capa
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleCoverImageChange}
                        />
                    </Button>
                    <TextField
                        margin="dense"
                        id="coverImageUri"
                        label="Imagem de Capa Selecionada"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={coverImageFileName}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <TextField
                        margin="dense"
                        id="excerpt"
                        label="Resumo"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={excerpt}
                        onChange={(e) => setExcerpt(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        component="label" // Faz o botão agir como um label para o input de arquivo oculto
                    >
                        Carregar Imagem OG
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={handleOgImageChange}
                        />
                    </Button>
                    <TextField
                        margin="dense"
                        id="ogImage"
                        label="Imagem OG Selecionada"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={ogImage}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <ReactQuill
                        theme="snow" // Tema do Quill; 'snow' é um dos temas padrão
                        value={content}
                        ref={quillRef}
                        onChange={setContent} // Atualiza o estado quando o conteúdo muda
                        modules={modules} // Opcional: Configurações de módulos se você quiser personalizar as ferramentas
                        // formats={NovoBlogPostModal.formats} // Opcional: Configurações de formatos se você quiser limitar as opções de formatação
                    />
                    {/* O campo de data pode precisar de um seletor de data específico dependendo da sua UI */}

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={publish}
                                onChange={(event) => setPublish(event.target.checked)}
                                name="publish"
                                color="primary"
                            />
                        }
                        label="Publicar"
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancelar</Button>
                <Button onClick={handleSave}>Salvar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NovoBlogPostModal;
