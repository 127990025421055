import React from 'react';
import Menu from '../components/Menu';

const Home: React.FC = () => {
    const jwt = localStorage.getItem('jwt');

    return (
        <div>
            <Menu />
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <h1>Bem-vindo à Página Inicial</h1>
                <p>O token JWT armazenado é: {jwt}</p>
            </div>
        </div>
    );
};

export default Home;
