// src/pages/Autores.tsx
import React, { useEffect, useState } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Menu from '../components/Menu'; // Ajuste o caminho conforme necessário
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../redux/store'
import { createAuthor, fetchAuthors } from '../redux/slices/authorsSlice';
import NovoAutorModal from '../components/NovoAutorModal';

const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
];

const Autores: React.FC = () => {
    // const [profilePictureUri, setProfilePictureUri] = useState('');
    const dispatch = useDispatch<AppDispatch>();
    const [modalOpen, setModalOpen] = useState(false);
    const authorsState = useSelector((state: RootState) => state.authorsState);

    useEffect(() => {
        dispatch(fetchAuthors());
    }, [dispatch]);

    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleSaveAuthor = (author: { name: string; profilePicture: File | null }) => {
        dispatch(createAuthor({ ...author }))
        console.log(author);
    };
    
    return (
        <>
            <Menu />
            <Box sx={{ height: 400, width: '100%', mt: 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleOpenModal}>Novo</Button>
                </Box>
                <NovoAutorModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    onSave={handleSaveAuthor}
                />
                <Typography variant="h4" gutterBottom component="div" sx={{ m: 2 }}>
                    Autores
                </Typography>
                <DataGrid
                    rows={authorsState.data}
                    columns={columns}
                    checkboxSelection
                />
            </Box>
        </>
    );
};

export default Autores;

