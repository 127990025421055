import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { firebaseAuth } from './configs/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import SignUp from './pages/SignUp';
import Home from './pages/Home';
import Login from './pages/Login';
import Autores from './pages/Autores';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import Blog from './pages/Blog';

function App() {

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    localStorage.setItem('jwt', token);
                })
            } else {
                localStorage.removeItem('jwt');
            }
        });
        return () => unsubscribe();
    }, []);

  return (
            <Provider store={store}>
                <Router>
                    <Routes>
                        <Route path="/signup" element={<SignUp />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/" element={<Home />} />
                        <Route path="/autores" element={<Autores />} />
                        <Route path="/blog" element={<Blog />} />
                    </Routes>
                </Router>
            </Provider>

  );
}

export default App;
